<template>
    <v-edit-dialog
        v-if="isEditing"
        :class="spanClass"
        :cancel-text="cancelText"
        :large="large"
        :save-text="saveText"
        @save="save">
        <span v-if="prefix != null" :class="spanClass">{{ prefix }}</span>
        <span v-if="value !== goal && inline">
            <span
                v-for="(increment, index) in displayCurrentProgressIncrements"
                :key="index"
                :class="spanClass">
                {{ increment.value | toDisplayNumber }}{{ increment.text }}{{ abbreviation ? '' : increment.value == 1 ? '' : 's' }}
            </span>
            <span v-if="isLengthyArray(displayCurrentProgressIncrements)" :class="spanClass">
                /
            </span>
        </span>
        <span 
            v-for="(increment, index) in displayGoalIncrements"
            :key="index"
            :class="(value > 0 && goal != null && (value >= goal || (!hideGoalIncrements && increment.unitValue <= goal))) ? ('success--text ' + spanClass) : spanClass">
            {{ increment.value | toDisplayNumber }}{{ increment.text }}{{ abbreviation ? '' : increment.value == 1 ? '' : 's' }}
        </span>
        <slot name="append" v-bind:value="value" v-bind:goal="goal">
            <span v-if="goal != null && (value > goal || displayDifference && value > 0 && value != goal)" :class="spanClass">
                ({{ value > goal ? '+' : (goal > value ? '-' : '') }}{{ value - goal | toDisplayNumber }})
            </span>
        </slot>
        <template v-slot:input>
            <BT-Number-Edit
                v-for="(increment, index) in currentProgressIncrements"
                :key="index"
                v-model.number="increment.value"
                :large="!small"
                single-line
                @input="incrementChanged"
                :suffix="'/' + (goalIncrements[index].value) + (increment.text == null ? '' : increment.text + (abbreviation ? '' : increment.value == 1 ? '' : 's'))"
                type="number" />
        </template>
    </v-edit-dialog>
    <div v-else>
        <span v-if="prefix != null" :class="spanClass">{{ prefix }}</span>
        <span v-if="value !== goal && inline">
            <span
                v-for="(increment, index) in displayCurrentProgressIncrements"
                :key="index"
                :class="spanClass">
                {{ increment.value | toDisplayNumber }}{{ increment.text }}{{ abbreviation ? '' : increment.value == 1 ? '' : 's' }}
            </span>
            <span v-if="isLengthyArray(displayCurrentProgressIncrements)" :class="spanClass">
                /
            </span>
        </span>
        <span 
            v-for="(increment, index) in displayGoalIncrements"
            :key="index"
            :class="(value > 0 && goal != null && (value >= goal || (!hideGoalIncrements && increment.unitValue <= goal))) ? ('success--text ' + spanClass) : spanClass">
            {{ increment.value | toDisplayNumber }}{{ increment.text }}{{ abbreviation ? '' : increment.value == 1 ? '' : 's' }}
        </span>
        <slot name="append" v-bind:value="value" v-bind:goal="goal">
            <span v-if="goal != null && (value > goal || displayDifference && value > 0 && value != goal)" :class="spanClass">
                ({{ value > goal ? '+' : (goal > value ? '-' : '') }}{{ value - goal | toDisplayNumber }})
            </span>
        </slot>
    </div>
</template>

<script>
import { firstBy } from 'thenby';

export default {
    name: 'BT-Increment-Quantity-Edit',
    data: function() {
        return {
            increments: null,
            isLoading: false,
        }
    },
    props: {
        abbreviation: {
            type: Boolean,
            default: true
        },
        cancelText: {
            type: String,
            default: 'Cancel'
        },
        displayDifference: {
            type: Boolean,
            default: true
        },
        goal: null,
        hideGoalIncrements: {
            type: Boolean,
            default: false
        },
        inline: {
            type: Boolean,
            default: false
        },
        isEditing: {
            type: Boolean,
            default: true
        },
        large: {
            type: Boolean,
            default: false
        },
        measurements: {
            type: Array,
            default: null
        },
        prefix: {
            type: String,
            default: null
        },
        productID: {
            type: String,
            default: null
        },
        saveText: {
            type: String,
            default: 'OK'
        },
        small: {
            type: Boolean,
            default: true
        },
        spanClass: {
            type: String,
            default: null
        },
        successValue: {
            type: Number,
            default: null
        },
        value: null //the raw quantity
    },
    computed: {
        displayCurrentProgressIncrements() {
            return this.inline ? this.currentProgressIncrements.filter(x => x.value != 0) : [];
        },
        displayGoalIncrements() {
            var fList = this.goalIncrements.filter(x => x.value != 0);
            if (this.isLengthyArray(fList)) {
                return fList;
            }
            else if (this.isLengthyArray(this.goalIncrements)) {
                return [this.goalIncrements[this.goalIncrements.length - 1]];
            }
            else {
                return [];
            }
        },
        currentProgressIncrements() {
            var qty = this.value;
            if (isNaN(qty)) {
                return [];
            }

            var iList = [];
            var remaining = qty;
            var used = 0;

            if (this.isLengthyArray(this.increments) && this.isLengthyArray(this.measurements)) {
                var l = this.increments.filter(y => this.measurements.some(m => m.id == y.measurementID) && y.productID == this.productID);

                l.sort(firstBy(x => x.units, 'desc'));

                l.forEach(increment => {
                    var m = this.measurements.find(y => y.id == increment.measurementID);
                    if (m != null) {
                        var tRemaining = (remaining % increment.units);
                        var newInc = {
                            text: this.abbreviation ? m.abbreviation : m.measurementName,
                            value: (remaining - tRemaining) / increment.units,
                            unitsPerPackage: increment.units,
                            unitValue: 0
                        }

                        used += (newInc.value * newInc.unitsPerPackage);
                        newInc.unitValue = used;
                        iList.push(newInc);

                        remaining = tRemaining;
                    }
                })
            }

            iList.push({
                text: iList.length == 0 ? '' : this.abbreviation ? 'u' : 'Unit',
                value: remaining,
                unitsPerPackage: 1,
                unitValue: qty
            })

            return iList;
        },
        goalIncrements() {
            if (this.goal == null || this.hideGoalIncrements) {
                return this.currentProgressIncrements;
            }
            
            var qty = this.goal;
            if (isNaN(qty)) {
                return [];
            }

            var iList = [];
            var remaining = qty;
            var used = 0;

            if (this.isLengthyArray(this.increments) && this.isLengthyArray(this.measurements)) {
                var l = this.increments.filter(y => this.measurements.some(m => m.id == y.measurementID) && y.productID == this.productID);

                l.sort(firstBy(x => x.units, 'desc'));

                l.forEach(increment => {
                    var m = this.measurements.find(y => y.id == increment.measurementID);
                    if (m != null) {
                        var tRemaining = (remaining % increment.units);
                        var newInc = {
                            text: this.abbreviation ? m.abbreviation : m.measurementName,
                            value: (remaining - tRemaining) / increment.units,
                            unitsPerPackage: increment.units,
                            unitValue: 0
                        }

                        used += (newInc.value * newInc.unitsPerPackage);
                        newInc.unitValue = used;
                        iList.push(newInc);

                        remaining = tRemaining;
                    }
                })
            }

            iList.push({
                text: iList.length == 0 ? '' : this.abbreviation ? 'u' : 'Unit',
                value: remaining,
                unitsPerPackage: 1,
                unitValue: qty
            })

            return iList;
        },
    },
    async mounted() {
        if (this.increments == null) {
            try {
                this.isLoading = true;
                this.$forceUpdate();

                this.increments = await this.$BlitzIt.store.getAll('stock-increments');
            }
            finally {
                this.isLoading = false;
            }
        }
    },
    methods: {
        incrementChanged() {
            var total = this.currentProgressIncrements.sum(x => x.value * x.unitsPerPackage);
            this.$emit('input', total);
            this.$emit('change', total);
        },
        save() {
            this.$emit('save');
        }
    }
}
</script>